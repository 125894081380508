import React from 'react'
import Link from '../components/Link'
import HalfSizeImage from '../components/HalfSizeImage'
import { One, Row, Three } from '../components/Grid'
import Image from '../components/Image'
import SEO from '../components/SEO'
import { H4, Section, SEOH1 } from '../components/Style'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/projects",
      "name": "Projects"
    }
  }]
}`

export default () => (
  <Layout>
    <SEO
      path="/projects"
      title="Front-end Portfolio – 500Tech"
      description="500Tech's front-end development portfolio including React apps, React Native, and Angular projects."
      ld={breadcrumbs}
    />

    <Section>
      <SEOH1>Featured projects</SEOH1>
    </Section>

    <Row>
      <One>
        <Link to="/projects/proggio" noUnderline>
          <HalfSizeImage path="projects/proggio/proggio-logo.png" />
        </Link>
        <p>
          Project management application with project plan visualization and
          team collaboration
        </p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>
            Interactive timeline, real-time collaboration, rich drag and drop
          </p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>React, Redux, NodeJS</p>
        </div>
        <p>
          <Link to="/projects/proggio">Learn more</Link>
        </p>
      </One>
      <Three>
        <Link to="/projects/proggio" noUnderline>
          <Image path="projects/proggio/proggio-1.jpg" />
        </Link>
      </Three>
    </Row>

    <Row>
      <Three>
        <Link to="/projects/phytech" noUnderline>
          <Image path="projects/phytech/phytech-1.jpg" />
        </Link>
      </Three>
      <One>
        <Link to="/projects/phytech" noUnderline>
          <HalfSizeImage path="projects/phytech/phytech-logo.png" />
        </Link>
        <p>Web and mobile field monitoring application and for farmers</p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Interactive map, data visualization, reports constructor</p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>React, React Native, Web Workers</p>
        </div>
        <p>
          <Link to="/projects/phytech">Learn more</Link>
        </p>
      </One>
    </Row>

    <Row>
      <One>
        <Link to="/projects/thetaray" noUnderline>
          <HalfSizeImage path="projects/thetaray/thetaray-logo.png" />
        </Link>
        <p>Advanced analytics anomaly detection platform</p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Big datasets, internationalization, data visualization</p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>AngularJS, Angular, MobX, RxJS, D3, SVG, AG Grid, HighCharts</p>
        </div>
        <p>
          <Link to="/projects/thetaray">Learn more</Link>
        </p>
      </One>
      <Three>
        <Link to="/projects/thetaray" noUnderline>
          <Image path="projects/thetaray/thetaray-1.jpg" />
        </Link>
      </Three>
    </Row>

    <Row>
      <Three>
        <Link to="/projects/unpakt" noUnderline>
          <Image path="projects/unpakt/unpakt-1.jpg" />
        </Link>
      </Three>
      <One>
        <Link to="/projects/unpakt" noUnderline>
          <HalfSizeImage path="projects/unpakt/unpakt-logo.png" />
        </Link>
        <p>Helping people move by finding the best available mover</p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Interactive calculator, online payments</p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>AngularJS, Ruby on Rails, CoffeeScript</p>
        </div>
        <p>
          <Link to="/projects/unpakt">Learn more</Link>
        </p>
      </One>
    </Row>

    <Row>
      <One>
        <Link to="/projects/jfrog" noUnderline>
          <HalfSizeImage path="projects/jfrog/jfrog-logo.png" />
        </Link>
        <p>
          Universal artifact repository manager for any language and technology
        </p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Directory tree, rich UI library, role-based views</p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>AngularJS</p>
        </div>
        <p>
          <Link to="/projects/jfrog">Learn more</Link>
        </p>
      </One>
      <Three>
        <Link to="/projects/jfrog" noUnderline>
          <Image path="projects/jfrog/jfrog-1.jpg" />
        </Link>
      </Three>
    </Row>

    <Row>
      <Three>
        <Link to="/projects/mimic" noUnderline>
          <Image path="projects/mimic/mimic-cover.png" />
        </Link>
      </Three>
      <One>
        <Link to="/projects/mimic" noUnderline>
          <HalfSizeImage path="projects/mimic/mimic-logo.png" />
        </Link>
        <p>Open-source dev tool for mocking server responses in a browser</p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Response mocking, search and filtering</p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>React</p>
        </div>
        <p>
          <Link to="/projects/mimic">Learn more</Link>
        </p>
      </One>
    </Row>

    <Row>
      <One>
        <Link to="/projects/bitsofgold" noUnderline>
          <HalfSizeImage path="projects/bitsofgold/bitsofgold-logo.png" />
        </Link>
        <p>Israel’s largest Bitcoin and Ethereum exchange</p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Blockchain, Money exchange, Identity verification</p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>Ruby on Rails, AngularJS</p>
        </div>
        <p>
          <Link to="/projects/bitsofgold">Learn more</Link>
        </p>
      </One>
      <Three>
        <Link to="/projects/bitsofgold" noUnderline>
          <Image path="projects/bitsofgold/bitsofgold-1.jpg" />
        </Link>
      </Three>
    </Row>

    <Row>
      <Three>
        <Link to="/projects/farmdog" noUnderline>
          <Image path="projects/farmdog/farmdog.png" />
        </Link>
      </Three>
      <One>
        <Link to="/projects/farmdog" noUnderline>
          <HalfSizeImage path="projects/farmdog/farmdog-logo.png" />
        </Link>
        <p>Mobile scouting app for farmers</p>
        <div className="block smaller">
          <H4>Features</H4>
          <p>Interactive Map, Offline Support, Dynamic Forms
          </p>
        </div>
        <div className="block smaller">
          <H4>Technologies</H4>
          <p>React Native, Redux, Maps APIs, SQLite</p>
        </div>
        <p>
          <Link to="/projects/farmdog">Learn more</Link>
        </p>
      </One>
    </Row>

    <ContactForm />
  </Layout>
)
